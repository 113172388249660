import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'shared';
import { BrowserRefreshService } from './browser-refresh.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Client Remediation Portal';
  loggedIn = false;
  isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;
  subscription: Subscription;


  constructor(private authService: AuthenticationService, private router: Router, private refreshStatus: BrowserRefreshService) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.refreshStatus.setRefreshStatus(!router.navigated);
      }
    });
  }

  ngOnInit(): void {

    this.authService.handleRedirect().subscribe({
      next: () => {
        this.checkAccount();
        if (!this.loggedIn) {
          console.trace("logging in")
          this.authService.login();
        }
      },
      error: (err) => this.authService.processAuthError(err)
    });
  }

  checkAccount() {
    this.loggedIn = this.authService.isLoggedIn();
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
