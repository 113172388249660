import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MsalBroadcastService, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { ENVIRONMENT, SharedModule } from 'shared';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { ApprovalComponent } from './application-detail/approval/approval.component';
import { RejectComponent } from './application-detail/reject/reject.component';
import { ReturnComponent } from './application-detail/return/return.component';
import { ValidationErrorsComponent } from './application-detail/validation-errors/validation-errors.component';
import { ApplicationListComponent } from './home/application-list/application-list.component';
import { MatPaginatorIntlApplicationList } from './home/application-list/application-list.matpaginatorintl';
import { HomeComponent } from './home/home.component';
import { CreateNewAppComponent } from './home/create-new-app/create-new-app.component';
import { BrowserRefreshService } from './browser-refresh.service'
import { SessionStorageService } from './home/application-list/session-storage.service'
import { UploadPaymentPendingComponent } from './home/upload-paymentpending/upload-payment.component';
import {UploadPaidComponent} from './home/upload-paid/upload-paid.component';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: `${environment.redirectBase}`,
      authority: environment.loginAuthority,
      postLogoutRedirectUri: `${environment.redirectBase}`,
      knownAuthorities: [`${environment.authorityBase}`]
    },
    cache: {
      storeAuthStateInCookie: isIE // set to true for IE 11
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.backendURL, environment.backendScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ApplicationListComponent,
    ApplicationDetailComponent,
    ApprovalComponent,
    ReturnComponent,
    RejectComponent,
    ValidationErrorsComponent,
    UploadPaymentPendingComponent,
    CreateNewAppComponent,
    UploadPaidComponent
  ],
  exports: [MatBadgeModule],
  imports: [
    AppRoutingModule,
    SharedModule,
    MatBadgeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    BrowserRefreshService,
    SessionStorageService,
    { provide: LOCALE_ID, useValue: "en-NZ" },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlApplicationList }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
