<div class="container">
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngIf="loading; then loadingDisplay else displayApplication"></ng-container>
  </div>
</div>

<ng-template #loadingDisplay>
  <mat-spinner diameter="30"></mat-spinner>
</ng-template>

<ng-template #displayApplication>
  <div class="application-container">
    <ng-container *ngIf="!failedLoad; else failureMessage">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="center center">
        <h1 class="page-title">
          <span *ngIf="readOnly">Viewing</span>
          <span *ngIf="!readOnly">Editing</span>
          application for {{application.additionalDetails.b2cEmail}}
        </h1>
        <lib-status-indicator [statusName]="StatusName[application.applicationDetails.status]"></lib-status-indicator>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-raised-button color="secondary" routerLink="/">
          <mat-icon inline="true">arrow_back</mat-icon>Back to all applications
        </button>
        <div *ngIf="(adminActionsAvailable || adminAsUserActionsAvailable || userIncompleteReturned || approvedStatus) && appUnlocked"
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <button *ngIf="adminActionsAvailable" mat-raised-button matTooltip="Approve" color="primary"
            (click)="approve()">
            Approve
          </button>
          <button *ngIf="adminActionsAvailable && !application.additionalDetails.adminControlled == true"
            mat-raised-button matTooltip="Return to User" color="secondary" (click)="returnToUser(false)">
            Return to User
          </button>
          <button *ngIf="approvedStatus && !application.additionalDetails.adminControlled == true"
            mat-raised-button matTooltip="Return to User" color="secondary" (click)="returnToUser(false)">
            Return to User
          </button>
          <button *ngIf="adminActionsAvailable && application.additionalDetails.adminControlled == true"
            mat-raised-button matTooltip="Return to Admin" color="secondary" (click)="returnToUser(true)">
            Return to Admin
          </button>
          <button *ngIf="!approvedStatus" mat-raised-button matTooltip="Reject" color="warn" (click)="reject()">
            Reject
          </button>
        </div>
        <h3 *ngIf="application.additionalDetails.adminControlled == true" class="padded-title-row">
          <mat-icon inline="true">supervisor_account</mat-icon> This application was created on behalf of a user by an
          administrator
        </h3>
        <h3 *ngIf="lockStatus == LockStatus.Pending" class="padded-title-row">
          <mat-icon inline="true">lock</mat-icon>
          <mat-icon color="accent" inline="true">hourglass_bottom</mat-icon> The lock for this application is currently
          being acquired
        </h3>
        <h3 *ngIf="lockStatus == LockStatus.Error" class="padded-title-row">
          <mat-icon inline="true">lock</mat-icon>
          <mat-icon color="warn" inline="true">error</mat-icon> There was an error acquiring the lock for this
          application - please refresh to try again
        </h3>
        <h3 *ngIf="lockStatus == LockStatus.LockedBySelf" class="padded-title-row">
          <mat-icon inline="true">lock</mat-icon>
          <mat-icon color="warn" inline="true">error</mat-icon> This application is locked by you in another tab/window
        </h3>
        <h3 *ngIf="lockStatus == LockStatus.LockedByOther" class="padded-title-row">
          <mat-icon inline="true">lock</mat-icon>
          <mat-icon color="warn" inline="true">error</mat-icon> This application is locked by another administrator
        </h3>
        <h3 *ngIf="lockStatus == LockStatus.Acquired" class="padded-title-row">
          <mat-icon inline="true">lock</mat-icon>
          <mat-icon color="primary" inline="true">done</mat-icon> You currently have the lock on this application
        </h3>
        <mat-accordion multi>
          <mat-expansion-panel
            *ngIf="application.applicationDetails.status == ApplicationStatus.RemPaymentPending || application.applicationDetails.status == ApplicationStatus.RemPaid "
            expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Remediation Payment Details</mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutGap="12px">
              <div fxLayout="row" fxLayoutAlign="center center">
                <table mat-table [dataSource]="calculatedRemediationDetails" class="mat-elevation-z8">

                  <ng-container matColumnDef="assignedTime">
                    <th mat-header-cell *matHeaderCellDef> Assigned Date </th>
                    <td mat-cell *matCellDef="let calculatedRemediationDetail">
                      <div class="mobile-label">Assigned Date</div>
                      {{calculatedRemediationDetail.assignedTime | date:'yyyy-MM-dd'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="latestBatchID">
                    <th mat-header-cell *matHeaderCellDef> Batch ID </th>
                    <td mat-cell *matCellDef="let calculatedRemediationDetail">
                      <div class="mobile-label">Batch ID</div>
                      {{calculatedRemediationDetail.latestBatchID}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="latestRecalculatedDate">
                    <th mat-header-cell *matHeaderCellDef> Calculation Date </th>
                    <td mat-cell *matCellDef="let calculatedRemediationDetail">
                      <div class="mobile-label">Calculation Date</div>
                      {{calculatedRemediationDetail.latestRecalculatedDate | date:'yyyy-MM-dd'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalLeavePayable">
                    <th mat-header-cell *matHeaderCellDef> Calculated Leave Amount </th>
                    <td mat-cell *matCellDef="let calculatedRemediationDetail">
                      <div class="mobile-label">Calculated Leave Amount</div>
                      {{calculatedRemediationDetail.totalLeavePayable}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalTerminationPayable">
                    <th mat-header-cell *matHeaderCellDef> Calculated Termination Amount </th>
                    <td mat-cell *matCellDef="let calculatedRemediationDetail">
                      <div class="mobile-label">Calculated Termination Amount</div>
                      {{calculatedRemediationDetail.totalTerminationPayable}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalRecalculatedPayable">
                    <th mat-header-cell *matHeaderCellDef> Calculated Amount </th>
                    <td mat-cell *matCellDef="let calculatedRemediationDetail">
                      <div class="mobile-label">Calculated Amount</div>
                      {{calculatedRemediationDetail.totalRecalculatedPayable}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="item-description">
                    <th mat-header-cell *matHeaderCellDef colspan="6">
                      <h4>Calculated Remediation Details</h4>
                    </th>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['item-description']" class="title-row">
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="calculatedRemediationDisplayedColumns"
                    class="example-first-header-row">
                  </tr>

                  <tr mat-row *matRowDef="let row; columns: calculatedRemediationDisplayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell-message" colspan="6"> No calculated remediation lines to display </td>
                  </tr>
                </table>
              </div>

              <div fxLayout="row" fxLayoutAlign="center center">
                <table mat-table [dataSource]="paidRemediationDetails" class="mat-elevation-z8">

                  <ng-container matColumnDef="assignedTime">
                    <th mat-header-cell *matHeaderCellDef> Assigned Date </th>
                    <td mat-cell *matCellDef="let paidRemediationDetail">
                      <div class="mobile-label">Assigned Date</div>
                      {{paidRemediationDetail.assignedTime | date:'yyyy-MM-dd'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="terminationDate">
                    <th mat-header-cell *matHeaderCellDef> Termination Date </th>
                    <td mat-cell *matCellDef="let paidRemediationDetail">
                      <div class="mobile-label">Termination Date</div>
                      {{paidRemediationDetail.terminationDate}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="allowanceCode">
                    <th mat-header-cell *matHeaderCellDef> Allowance Code </th>
                    <td mat-cell *matCellDef="let paidRemediationDetail">
                      <div class="mobile-label">Allowance Code</div>
                      {{paidRemediationDetail.allowanceCode}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let paidRemediationDetail">
                      <div class="mobile-label">Description</div>
                      {{paidRemediationDetail.description}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="historicalAllowanceID">
                    <th mat-header-cell *matHeaderCellDef>
                      Historical Allowance ID
                    </th>
                    <td mat-cell *matCellDef="let paidRemediationDetail">
                      <div class="mobile-label">Historical Allowance ID</div>
                      {{paidRemediationDetail.historicalAllowanceID}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                    <td mat-cell *matCellDef="let paidRemediationDetail">
                      <div class="mobile-label">Amount</div>
                      {{paidRemediationDetail.totalAmount}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="item-description">
                    <th mat-header-cell *matHeaderCellDef colspan="6">
                      <h4>Payroll Paid Remediation Details</h4>
                    </th>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['item-description']" class="title-row">
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="paidRemediationDisplayedColumns"
                    class="example-first-header-row">
                  </tr>

                  <tr mat-row *matRowDef="let row; columns: paidRemediationDisplayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell-message" colspan="6"> No paid remediation lines to display </td>
                  </tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #commentsSection>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Comments ({{ comments.length }})
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column">
              <div *ngIf="appUnlocked" fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field fxFlex appearance="outline">
                  <mat-label>Add a comment</mat-label>
                  <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                    [formControl]="commentForm"></textarea>
                </mat-form-field>
                <lib-button-spinner class="comment-button" matTooltip="Add" [spinning]="commentSaving" color="secondary"
                  (buttonClick)="addComment()" [disabled]="commentForm.value == null">
                  <mat-icon>add</mat-icon>
                </lib-button-spinner>
              </div>
              <div *ngFor="let comment of comments">
                <mat-form-field fxFlex>
                  <mat-label>{{comment.user}} on {{comment.time | date:'d MMM, y, h:mm a'}}</mat-label>
                  <textarea matInput cdkTextareaAutosize disabled="true" [value]="comment.comment"
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #supportRequestSection>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Support Requests ({{ supportRequests.length }})
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column">
              <div *ngFor="let request of supportRequests">
                <mat-form-field fxFlex>
                  <mat-label>{{request.user}} on {{request.time | date:'d MMM, y, h:mm a'}}</mat-label>
                  <textarea matInput cdkTextareaAutosize disabled="true" [value]="generateSupportMessage(request)"
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="!application.applicationDetails.status == ApplicationStatus.Incomplete"
            expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Validation Errors</mat-panel-title>
            </mat-expansion-panel-header>
            <app-validation-errors [minorErrors]="application.additionalDetails.minorErrors"
              [criticalErrors]="application.additionalDetails.criticalErrors"></app-validation-errors>
          </mat-expansion-panel>
          <ng-container *ngIf="!adminAsUserActionsAvailable">
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>Supporting Documents</mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                <lib-supporting-documents
                  *ngIf="application.applicationDetails.status == ApplicationStatus.AwaitingReview"
                  [editable]="appUnlocked" [formGroup]="appDetails.supportingDocumentsForm"
                  [appId]="some(applicationId)">
                </lib-supporting-documents>
                <lib-button-spinner [spinning]="downloadingDocuments" color="accent" buttonType="mat-raised-button"
                  (buttonClick)="downloadSupportingDocuments()">Download <mat-icon inline="true">arrow_downward
                  </mat-icon>
                </lib-button-spinner>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>Details</mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayoutAlign="center center">
                <div fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutAlign="start start">
                  <lib-create-application #appDetails [application]="some(application)" [readOnly]="readOnly"
                    [viewerMode]="true" [adminMode]="true" [createApplication]="createApplicationFunction"
                    [contactEmail]="b2cEmail">
                  </lib-create-application>
                  <div *ngIf="adminActionsAvailable" class="edit-application-button">
                    <lib-button-spinner *ngIf="!readOnly && appUnlocked" matTooltip="Save"
                      [spinning]="appDetailsComponent.saving" color="secondary" (buttonClick)="save()">
                      <mat-icon>save</mat-icon>
                    </lib-button-spinner>
                    <button *ngIf="readOnly && appUnlocked" matTooltip="Edit" mat-button color="secondary"
                      (click)="enableEdit()">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <mat-expansion-panel *ngIf="adminAsUserActionsAvailable" expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Application</mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutAlign="center center">
              <lib-create-application #appCreation [application]="some(application)" [adminMode]="true"
                [readOnly]="readOnly" [createApplication]="createApplicationFunction" [appId]="some(applicationId)"
                [contactEmail]="b2cEmail">
              </lib-create-application>
              <lib-button-spinner *ngIf="!readOnly" [spinning]="appCreation.submitting" buttonType="mat-raised-button"
                color="primary"
                [disabled]="appCreation.applicationForm.invalid || appCreation.supportingDocumentsForm.invalid"
                (buttonClick)="submit()">Submit Application
              </lib-button-spinner>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #failureMessage>
  <p>There was an error accessing the application. Please try again later.</p>
</ng-template>