<div class="container">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center start"
    fxLayoutAlign.sm="center stretch" fxLayoutAlign.xs="center stretch" fxLayoutGap="20px" fxLayoutGap.xs="0px"
    fxLayoutGap.sm="0px">

    <div fxFlex>
      <mat-form-field appearance="fill">
        <mat-label>Filter full names and email addresses</mat-label>
        <input matInput [formControl]="filterInput" (keyup)="applyTextFilter($event)"
          placeholder="Ex. john.doe@example.com" [disabled]="dataSource.loading$ | async">
        <mat-error *ngIf="filterInput.invalid">The filter accepts up to {{maxSearchWords}} search terms of at least
          {{minSearchWordLength}} characters each</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex>
      <mat-form-field appearance="fill">
        <mat-label>Filter Statuses</mat-label>
        <mat-select [formControl]="statuses" multiple #statusSelect [disabled]="dataSource.loading$ | async">
          <mat-select-trigger>
            <div *ngIf="statuses.value?.includes('All')">
              All Statuses
            </div>
            <div *ngIf="!statuses.value?.includes('All')">
              {{statuses.value[0] ? getDisplayNameForStatusFilter(statuses.value[0]) : ''}}
              <span *ngIf="statuses.value?.length > 1 && !statuses.value?.includes('All')" class="additional-selection">
                (+{{statuses.value.length - 1}} {{statuses.value?.length === 2 ? 'other' : 'others'}})
              </span>
            </div>
          </mat-select-trigger>
          <mat-option class="select-option status-filter-option" #allSelected [value]="'All'" (click)="toggleAll()">
            Select All</mat-option>
          <mat-option class="select-option status-filter-option" *ngFor="let status of statusList" [value]="status"
            (click)="toggleIndividual()">
            {{getDisplayNameForStatusFilter(status)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex>
      <mat-form-field appearance="fill">
        <mat-label>Filter Created By</mat-label>
        <mat-select [formControl]="submissionType" #submissionTypeChange [disabled]="dataSource.loading$ | async">
          <mat-option value="all">All</mat-option>
          <mat-option value="user">Created by User</mat-option>
          <mat-option value="admin">Created by Administrator</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="clear-search-div">
      <div>
        <button mat-raised-button (click)="reloadPage()" color="basic">Clear filters</button>
      </div>
    </div>

  </div>
</div>
<div class="mat-elevation-z8">

  <table mat-table [dataSource]="dataSource" matSort [(matSortActive)]="sortOrder"
    [(matSortDirection)]="sortOrderDirection" matSortDisableClear>

    <!-- Full Name Column -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dataSource.loading$ | async"> Full Name
      </th>
      <td mat-cell *matCellDef="let application">
        <div class="mobile-label">Full Name</div>
        {{application.fullName}}
      </td>
    </ng-container>

    <!-- Application Email Address Column -->
    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dataSource.loading$ | async"> Application
        Email Address </th>
      <td mat-cell *matCellDef="let application">
        <div class="mobile-label"> Email Address</div>
        <span class="table-value">{{application.emailAddress}} </span>
      </td>
    </ng-container>

    <!-- Application Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dataSource.loading$ | async"> Application
        Status </th>
      <td class="status-cell" mat-cell *matCellDef="let application">
        <div class="mobile-label">Application Status</div>
        <lib-status-indicator class="table-status-indicator" [statusName]="StatusName[application.status]">
        </lib-status-indicator>
      </td>
    </ng-container>

    <!-- Created By Column -->
    <ng-container matColumnDef="adminControlled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dataSource.loading$ | async"> Created By </th>
      <td mat-cell *matCellDef="let application">
        <div class="mobile-label">Created By</div>
        <span class="table-value">{{application.adminControlled ? 'Administrator' : 'User'}} </span>
      </td>
    </ng-container>

    <!-- Date Submitted Column -->
    <ng-container matColumnDef="submissionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dataSource.loading$ | async"> Date
        Submitted </th>

      <td mat-cell *matCellDef="let application">
        <div class="mobile-label">Date Submitted</div>
        <span class="table-value">{{application.submissionDate | date:'d MMM, y, h:mm a'}} </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row class="row-link" *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'table-loading': (dataSource.loading$ | async)}" [routerLink]="'/applications/' + row.applicationID">
    </tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell-message" colspan="4">No applications to display</td>
    </tr>
  </table>

  <mat-paginator [pageSize]="10" [hidePageSize]="true" [disabled]="dataSource.loading$ | async"></mat-paginator>

  <mat-progress-bar mode="indeterminate" *ngIf="dataSource.loading$ | async"></mat-progress-bar>
</div>