import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationStatus } from 'shared';
import { AdminService } from '../../admin.service';
import { StatusUpdateComponent } from '../status-update/status-update.component';

@Component({
  selector: 'app-reject',
  templateUrl: './reject.component.html',
  styleUrls: ['./reject.component.css']
})
export class RejectComponent extends StatusUpdateComponent {
  status: ApplicationStatus = ApplicationStatus.Rejected;

  constructor(adminService: AdminService, @Inject(MAT_DIALOG_DATA) data: string, snackBar: MatSnackBar) {
    super(adminService, data, snackBar);
  }
}
