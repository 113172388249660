<div class="container">
  <div fxLayoutAlign="center">
    <mat-card class="card">
      <mat-card-title>
        <h1>Applications</h1>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap=10px fxLayoutAlign="center center">
            <div fxFlex="50%">
              <button mat-raised-button color="accent" (click)="openUploadDialog()">Upload Payment Pending</button>
            </div>
            <div></div>
            <div fxFlex="50%">
              <button mat-raised-button color="accent" (click)="openPaidUploadDialog()">Upload Paid Status</button>
            </div>
            <div></div>
            <div fxFlex="50%">
            <button mat-raised-button color="primary" (click)="openCreateAppDialog()">Create Application</button>
          </div>
          </div>       
        </div>
        <app-application-list #appList></app-application-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>