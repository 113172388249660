import { ApplicationStatus } from 'shared';

export type OrderColumn = "fullName" | "emailAddress" | "status" | "submissionDate" | "adminControlled";
export type OrderColumnDirection = "asc" | "desc";
export type CreatedBy = "user" | "admin";

export interface PagedApplicationQuery {
  order: OrderColumn;
  orderDirection: OrderColumnDirection;
  maxItems: number;
  filterByStatuses?: ApplicationStatus[];
  filterByText?: string[];
  filterByCreator?: CreatedBy;
  continuationToken?: string;
}

export interface PagedApplications {
  records: ApplicationOverview[];
  count: number;
  continuationToken?: string;
}

export interface ApplicationOverview {
  fullName: string;
  emailAddress: string;
  status: ApplicationStatus;
  submissionDate: string;
  applicationID: string;
  adminControlled: boolean;
}

export enum LockStatus {
  Acquired = 0,
  LockedBySelf = 1,
  LockedByOther = 2,
  Pending = 3,
  Error = 4
}

export interface StatusRequest {
  status: ApplicationStatus,
  reviewerMessage: string
}

export const StatusName: { [key in ApplicationStatus]: [string, string] } = {
  [ApplicationStatus.Incomplete]: ["Incomplete", "secondary"],
  [ApplicationStatus.Submitted]: ["Awaiting Review", "primary"],
  [ApplicationStatus.AwaitingReview]: ["Awaiting Review", "accent"],
  [ApplicationStatus.Returned]: ["Returned to User", "secondary"],
  [ApplicationStatus.IdConfirmed]: ["Approved", "primary"],
  [ApplicationStatus.RemPending]: ["Amount Pending", "primary"],
  [ApplicationStatus.RemPaymentPending]: ["Payment Pending", "primary"],
  [ApplicationStatus.RemPaid]: ["Paid", "primary"],
  [ApplicationStatus.Rejected]: ["Rejected", "warn"],
  [ApplicationStatus.NoRemediationCalculated]: ["No Remediation Amount", "primary"]
}
