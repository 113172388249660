import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as A from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/function';
import * as O from 'fp-ts/es6/Option';
import { Option, some } from 'fp-ts/es6/Option';
import { Observable, Subject, timer } from 'rxjs';
import { map, retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AdminApplicationResponse, ApplicationRequest, ApplicationStatus, Comment, CreateApplicationComponent, CalculatedRemediationDetail, PaidRemediationDetail, SupportRequest } from 'shared';
import { AdminService } from '../admin.service';
import { ApprovalComponent } from './approval/approval.component';
import { RejectComponent } from './reject/reject.component';
import { ReturnComponent } from './return/return.component';
import { StatusUpdateComponent } from './status-update/status-update.component';
import { LockStatus, StatusName } from '../admin-models';
import { v4 as uuid } from 'uuid';
import { OnDestroy } from '@angular/core';
import { SessionStorageService } from '../home/application-list/session-storage.service';

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    }
  ]
})
export class ApplicationDetailComponent implements OnInit, OnDestroy {

  @ViewChild('appDetails') appDetailsComponent: CreateApplicationComponent;
  @ViewChild('appCreation') appCreationComponent: CreateApplicationComponent;
  @ViewChild('commentsSection') commentsSection: MatExpansionPanel;
  some = some;
  LockStatus: typeof LockStatus = LockStatus;
  applicationId: string;
  application: AdminApplicationResponse;
  b2cEmail: string;
  loading: boolean = false;
  failedLoad: boolean = false;
  readOnly: boolean = true;
  editEnabled: boolean = false;
  appUnlocked: boolean = false;
  sessionId: string;
  commentForm: UntypedFormControl;
  commentSaving: boolean = false;
  comments: Comment[];
  lockStatus: LockStatus = LockStatus.Pending;
  supportRequests: SupportRequest[];
  paidRemediationDetails: PaidRemediationDetail[];
  calculatedRemediationDetails: CalculatedRemediationDetail[];
  adminActionsAvailable: boolean = false;
  adminAsUserActionsAvailable: boolean = false;
  ApplicationStatus = ApplicationStatus;
  dialogConfig: MatDialogConfig;
  StatusName = StatusName;
  downloadingDocuments: boolean = false;
  private stopPolling = new Subject();
  adminIncomplete: boolean;
  userIncompleteReturned: boolean;
  adminReturned: boolean;
  approvedStatus: boolean;
  paidRemediationDisplayedColumns: string[] = ['assignedTime', 'terminationDate', 'allowanceCode', 'description', 'historicalAllowanceID', 'totalAmount'];
  calculatedRemediationDisplayedColumns: string[] = ['assignedTime', 'latestBatchID', 'latestRecalculatedDate', 'totalLeavePayable', 'totalTerminationPayable', 'totalRecalculatedPayable'];

  createApplicationFunction: (app: ApplicationRequest) => Observable<AdminApplicationResponse>;

  constructor(private route: ActivatedRoute, private adminService: AdminService, private snackBar: MatSnackBar, private dialog: MatDialog, private sessionStorage: SessionStorageService) { }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  ngOnInit(): void {
    let maybeId = this.sessionStorage.getItem('sessionId')
    if (!maybeId) {
      maybeId = uuid()
      this.sessionStorage.setItem('sessionId', maybeId)
    }
    this.sessionId = maybeId;
    this.getApplication();
    this.commentForm = new UntypedFormControl();
    this.dialogConfig = { position: { top: '200px' }, width: '400px', minHeight: '170px', data: this.applicationId };
    this.createApplicationFunction = app => this.adminService.updateApplication(this.applicationId, app);
    this.startLockPolling();
  }

  startLockPolling(): void {
    timer(0, 5000).pipe(
      switchMap(() => {
        return this.adminService.getApplicationLock(this.applicationId, this.sessionId)
      }),
      retry(2),
      share(),
      takeUntil(this.stopPolling)
    ).subscribe(
      status => {
        //First update
        if (this.lockStatus == LockStatus.Pending && this.lockStatus != status) {
          this.setLockStatus(status);
          this.setReadOnlyState();
          this.snackBar.open("You have acquired the lock for this application.", "Dismiss")
        }
        //Lost lock
        else if (this.lockStatus == LockStatus.Acquired && this.lockStatus != status) {
          if (status == LockStatus.LockedByOther) {
            this.snackBar.open("This lock for this application was taken by another administrator.", "Dismiss")
          } else if (status == LockStatus.LockedBySelf) {
            this.snackBar.open("This lock for this application was taken by you in a different tab/window.", "Dismiss")
          }
          this.setLockStatus(status);
          this.getApplication();
        }
        //Lock acquired
        else if (status == LockStatus.Acquired && this.lockStatus != status) {
          this.snackBar.open("You have acquired the lock for this application.", "Dismiss")
          this.setLockStatus(status);
          this.getApplication();
        }
      },
      err => {
        console.error(err);
        this.snackBar.open("There was an error locking the application for changes. Please refresh the page.", "Dismiss")
        this.setLockStatus(LockStatus.Error);
      });
  }

  setLockStatus(status: LockStatus): void {
    this.lockStatus = status;
    this.appUnlocked = status == LockStatus.Acquired;
  }

  generateSupportMessage(supportRequest: SupportRequest): string {
    let phone = supportRequest.phone == null ? "N/A" : supportRequest.phone;
    return `Name: ${supportRequest.name}\nEmail: ${supportRequest.email}\nPhone: ${phone}\n\n${supportRequest.message}`
  }

  handleStatusUpdate<T extends StatusUpdateComponent>(dialogRef: MatDialogRef<T>, expandComments: boolean = false): void {
    dialogRef.componentInstance.updated.subscribe(app => {
      this.initApplication(app);
      dialogRef.close();
      if (expandComments) {
        this.commentsSection.expanded = true;
      }
    })
  }

  openDialog<T extends StatusUpdateComponent>(component: ComponentType<T>): MatDialogRef<T> {
    return this.dialog.open(component, this.dialogConfig);
  }

  approve() {
    this.handleStatusUpdate(this.openDialog(ApprovalComponent));
  }

  returnToUser(adminControlled: boolean) {
    let dialogRef = this.openDialog(ReturnComponent);
    let instance = dialogRef.componentInstance;
    instance.adminControlled = adminControlled;
    this.handleStatusUpdate(dialogRef, true);
  }


  reject() {
    this.handleStatusUpdate(this.openDialog(RejectComponent));
  }

  save() {
    this.appDetailsComponent.saved.subscribe(_ => {
      this.editEnabled = false;
      this.setReadOnlyState();
    });
    this.appDetailsComponent.save();
  }

  submit() {
    this.appCreationComponent.submitted.subscribe(a => this.initApplication(a as AdminApplicationResponse));
    this.appCreationComponent.submit();
  }

  addComment(): void {
    this.commentSaving = true;
    this.adminService.addComment(this.applicationId, this.commentForm.value)
      .subscribe(comment => {
        this.comments.unshift(comment);
        this.commentSaving = false;
        this.commentForm.reset();
        this.snackBar.open("Comment added successfully.", "Dismiss")
      }, err => {
        console.error(err);
        this.snackBar.open("There was an error saving your comment. Please try again later.", "Dismiss")
        this.commentSaving = false;
      })
  }

  enableEdit(): void {
    if (!this.appUnlocked) {
      this.snackBar.open("Unable to enable edit as you do not currently hold the lock on the application.", "Dismiss")
    } else {
      this.editEnabled = true;
      this.setReadOnlyState();
    }
  }

  getApplication(): void {
    this.loading = true;
    this.route.paramMap
      .pipe(
        map(params => params.get("applicationId")),
        tap(id => this.applicationId = id),
        switchMap(id => this.adminService.getApplication(id))
      ).subscribe(app => {
        this.initApplication(app);
        this.loading = false;
      },
        err => {
          console.error(err);
          this.failedLoad = true;
          this.loading = false;
        });
  }

  addReviewerMessagesToComments(maybeReviewMessages: Option<Comment[]>): void {
    pipe(
      maybeReviewMessages,
      O.map(cs => {
        let modifiedComments = pipe(cs,
          A.map(c => {
            return {
              comment: c.comment,
              user: `Review comment by ${c.user}`,
              time: c.time
            } as Comment
          }))
        modifiedComments.forEach(c => this.comments.unshift(c))
      }))
  }

  initApplication(app: AdminApplicationResponse): void {
    this.application = app
    this.comments = pipe(
      O.fromNullable(app.additionalDetails.comments),
      O.getOrElse(() => [])
    );
    this.paidRemediationDetails = pipe(
      O.fromNullable(app.additionalDetails.paidRemediationDetails),
      O.getOrElse(() => [])
    );
    this.calculatedRemediationDetails = pipe(
      O.fromNullable(app.additionalDetails.calculatedRemediationDetails),
      O.getOrElse(() => [])
    );
    this.supportRequests = pipe(
      O.fromNullable(app.additionalDetails.supportRequests),
      O.getOrElse(() => [])
    );
    this.addReviewerMessagesToComments(O.fromNullable(app.additionalDetails.reviewerMessages));
    this.comments = this.comments.sort((a, b) => a.time < b.time ? 1 : -1);
    this.supportRequests = this.supportRequests.sort((a, b) => a.time < b.time ? 1 : -1);
    this.adminActionsAvailable = this.application.applicationDetails.status == ApplicationStatus.AwaitingReview;
    this.adminIncomplete = this.application.additionalDetails.adminControlled && this.application.applicationDetails.status == ApplicationStatus.Incomplete;
    this.userIncompleteReturned = !this.application.additionalDetails.adminControlled && (this.application.applicationDetails.status == ApplicationStatus.Incomplete || this.application.applicationDetails.status == ApplicationStatus.Returned);
    this.adminReturned = this.application.additionalDetails.adminControlled && this.application.applicationDetails.status == ApplicationStatus.Returned;
    this.adminAsUserActionsAvailable = this.adminIncomplete || this.adminReturned;
    this.b2cEmail = this.application.additionalDetails.b2cEmail;
    this.approvedStatus = this.application.applicationDetails.status == ApplicationStatus.IdConfirmed;
    this.setReadOnlyState();
  }

  setReadOnlyState(): void {
    if (!this.appUnlocked) {
      this.readOnly = true;
    } else if (this.editEnabled) {
      this.readOnly = false;
    } else if (this.adminIncomplete || this.adminReturned) {
      this.readOnly = false;
    } else {
      this.readOnly = true;
    }
  }

  downloadSupportingDocuments(): void {
    this.downloadingDocuments = true;
    this.adminService.downloadSupportingDocuments(this.applicationId)
      .subscribe(req => {
        const blob = new Blob([req.body], { type: "application/zip" });
        var url = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.download = `${this.application.additionalDetails.b2cEmail}_supporting_documents.zip`;
        link.href = url;
        link.click();
        this.downloadingDocuments = false;
      },
        error => {
          console.error("Error downloading file", error)
          this.snackBar.open(`${error.status} ${error.message}`, "Dismiss")
          this.downloadingDocuments = false;
        })
  }

}
