<h3 mat-dialog-title>
  <ng-template [ngTemplateOutlet]="ReturnToText"></ng-template>
</h3>

<div mat-dialog-content class="mat-typography">
  <mat-form-field fxFlex appearance="outline">
    <mat-label>Please detail the changes which need to be made.</mat-label>
    <textarea matInput required cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
      [formControl]="messageForm"></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutGap=10px>
  <lib-button-spinner [spinning]="updating" color="primary" buttonType="mat-raised-button" (buttonClick)="update()"
    [disabled]="messageForm.invalid">
    <ng-template [ngTemplateOutlet]="ReturnToText"></ng-template>
  </lib-button-spinner>
  <button mat-button (mousedown)="close()" (click)="close()">Cancel</button>
</div>

<ng-template #ReturnToText>
  <ng-container *ngIf="adminControlled">Return to Admin</ng-container>
  <ng-container *ngIf="!adminControlled">Return to User</ng-container>
</ng-template>