import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminApplicationResponse, ApplicationStatus } from 'shared';
import { AdminService } from '../../admin.service';

@Component({
  template: '',
})
export abstract class StatusUpdateComponent implements OnInit {

  updated: EventEmitter<AdminApplicationResponse> = new EventEmitter<AdminApplicationResponse>();
  updating: boolean = false;
  errorCode: number = null;
  abstract status: ApplicationStatus;

  constructor(private adminService: AdminService, @Inject(MAT_DIALOG_DATA) public data: string, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  getMessage(): string {
    return null;
  }

  canUpdate(): boolean {
    return true;
  }

  update(): void {
    this.updating = true;
    this.adminService.updateApplicationStatus(this.data, this.status, this.getMessage())
      .subscribe(app => {
        this.updated.emit(app);
        this.updating = false;
      }, err => {
        console.error(err);
        this.errorCode = err.status;
        this.updating = false;
        this.snackBar.open("There was an error updating the application status. Please try again later.", "Dismiss");
      })
  }
}
