import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { CreatedBy, OrderColumn, OrderColumnDirection, StatusName } from '../../admin-models'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ApplicationStatus } from 'shared';
import { Subject, BehaviorSubject, pipe } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ApplicationOverviewDataSource, ApplicationPageDefinition } from './application-list.datasource'
import { AdminApplicationService } from '../../admin-application.service';
import * as O from 'fp-ts/es6/Option'
import * as E from 'fp-ts/es6/Either'
import * as F from 'fp-ts/es6/function'
import { Eq } from 'fp-ts/Eq'
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { BrowserRefreshService } from '../../browser-refresh.service';
import { SessionStorageService } from './session-storage.service';

const stringArrEq: Eq<O.Option<string[]>> = O.getEq<string[]>({
  equals: (l, r) => {
    let array1 = l.sort()
    let array2 = r.sort()
    return array1.length === array2.length && array1.every((value, index) => value === array2[index])
  }
})

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.css']
})
export class ApplicationListComponent implements OnInit, AfterViewInit {

  filterInput = new UntypedFormControl('', this.invalidQueryValidator());
  dataSource: ApplicationOverviewDataSource;
  displayedColumns: string[] = ['fullName', 'emailAddress', 'status', 'adminControlled', 'submissionDate']
  statusList: string[] = Object.keys(ApplicationStatus).map(k => ApplicationStatus[k]).filter((v): v is string => typeof v === 'string');
  statuses = new UntypedFormControl(this.statusList.concat(['All']).sort());
  submissionType = new UntypedFormControl('all');
  applicationStatusEnum = ApplicationStatus;
  defaultOrder: OrderColumn = 'fullName';
  defaultOrderDirection: OrderColumnDirection = 'asc';
  StatusName = StatusName;
  sortOrder: OrderColumn = this.defaultOrder;
  sortOrderDirection: OrderColumnDirection = this.defaultOrderDirection;
  searchFilter: SearchFilterDefinition = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('statusSelect') private statusSelect: MatSelect;
  @ViewChild('submissionTypeChange') private submissionTypeSelect: MatSelect;

  private textFilterSubWithDebounce$ = new Subject<O.Option<string[]>>();
  private textFilterSub$ = new Subject<O.Option<string[]>>();
  private pageDefSub$: BehaviorSubject<ApplicationPageDefinition>;
  private minSearchWordLength: number = 3;
  private maxSearchWords: number = 3;

  constructor(private adminApplicationService: AdminApplicationService, private _snackBar: MatSnackBar, private changeDetector: ChangeDetectorRef, private refreshStatus: BrowserRefreshService, private sessionStorage: SessionStorageService) { }

  setFormState(search: SearchFilterDefinition) {
    this.statuses.setValue(search.statuses)
    this.submissionType.setValue(search.submissionType)
    this.filterInput.setValue(search.filter)
  }

  setSortPageState(search: SearchFilterDefinition) {
    this.sort.sort({ id: null, start: 'desc', disableClear: false });
    this.sort.sort({ id: search.order, start: search.direction, disableClear: false });
    (this.sort.sortables.get(search.order) as MatSortHeader)?._setAnimationTransitionState({ toState: 'active' });
    this.changeDetector.detectChanges();
  }

  getFormState(): string {
    return btoa(JSON.stringify({
      order: this.sort.active,
      direction: this.sort.direction,
      statuses: this.statuses.value,
      submissionType: this.submissionType.value,
      filter: this.filterInput.value,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    }))
  }

  invalidQueryValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let value: string = control.value;
      let validated = this.parseSearchQuery(value)
      if (O.isSome(validated)) return null
      else return { invalidQuery: { value: value } }
    };
  }

  parseSearchQuery(value: string): O.Option<O.Option<string[]>> {
    if (value == "") return O.some(O.none);
    let parsed = value.toLowerCase().replace(/,/g, " ").split(" ").map(t => t.trim()).filter(v => v != "")
    if (parsed.length > this.maxSearchWords) return O.none
    if (parsed.length != parsed.filter(v => v.length >= this.minSearchWordLength).length) return O.none
    return O.some(O.some(parsed));
  }

  ngOnInit(): void {

    let isRefresh = this.refreshStatus.getRefreshStatus()
    if (isRefresh) {
      this.sessionStorage.removeItem('searchState')
    }

    this.dataSource = new ApplicationOverviewDataSource(this.adminApplicationService, isRefresh, this.sessionStorage)

    let maybeSearch = this.sessionStorage.getItem('searchState')
    if (maybeSearch) {
      let def = parseSearchFilter(maybeSearch);
      this.setFormState(def)
      this.searchFilter = def;
      let appDef: ApplicationPageDefinition = {
        filterByText: O.getOrElse<O.Option<string[]>>(() => O.none)(this.parseSearchQuery(def.filter)),
        order: def.order,
        filterByCreator: this.parseSubmissionType(def.submissionType),
        filterByStatuses: this.parseStatus(def.statuses),
        orderDirection: def.direction,
        pageIndex: def.pageIndex,
        pageSize: def.pageSize
      }
      this.pageDefSub$ = new BehaviorSubject<ApplicationPageDefinition>(appDef)
    } else {
      this.pageDefSub$ = new BehaviorSubject<ApplicationPageDefinition>({ filterByStatuses: O.none, filterByCreator: O.none, filterByText: O.none, pageIndex: 0, pageSize: 10, order: this.defaultOrder, orderDirection: this.defaultOrderDirection });
    }

  }

  reloadPage() {
    location.reload();
  }

  ngAfterViewInit() {

    if (this.searchFilter) {
      this.setSortPageState(this.searchFilter)
    }

    // Text filter subscription, that includes a debounce (for automatic keyup event), calls textFilterSub$.next
    this.textFilterSubWithDebounce$.pipe(
      debounceTime(1500)
    ).subscribe((maybeFilter: O.Option<string[]>) => {
      this.textFilterSub$.next(maybeFilter)
    });

    // Text filter subscription, without debounce (for enter instant search)
    this.textFilterSub$.pipe(
      distinctUntilChanged((l, r) => stringArrEq.equals(l, r))
    )
      .subscribe((maybeFilter: O.Option<string[]>) => {
        this.updatePageDefinition(maybeFilter)
      });

    // All page definition changes subscription
    this.pageDefSub$.pipe(
      delay(0), //https://blog.angular-university.io/angular-debugging/
      mergeMap(p => this.dataSource.loadPage(p))
    ).subscribe(maybeHasMore => {
      F.pipe(
        maybeHasMore,
        E.map(hasMore => {
          this.paginator.pageIndex = this.pageDefSub$.value.pageIndex;
          if (hasMore) {
            this.paginator.length = this.paginator.pageSize * (this.paginator.pageIndex + 2)
          } else {
            this.paginator.length = this.paginator.pageSize * (this.paginator.pageIndex + 1)
          }
        }),
        E.mapLeft(error => {
          console.error(error)
          this._snackBar.open("Failed to retrieve results. Please refresh and try again.", "Dismiss")
        })
      )
    }, e => {
      console.error(e)
      this._snackBar.open("Failed to retrieve results. Please refresh and try again.", "Dismiss")
    })

    this.submissionTypeSelect.selectionChange.subscribe(() => {
      this.updatePageDefinition(null, null, null, null, null, null, this.parseSubmissionType(this.submissionType.value))
    })

    this.sort.sortChange.subscribe(() => {
      this.updatePageDefinition(null, null, <OrderColumn>this.sort.active, <OrderColumnDirection>this.sort.direction)
    })

    this.paginator.page.subscribe(() => {
      this.updatePageDefinition(null, null, null, null, this.paginator.pageIndex, this.paginator.pageSize)
    })

    this.statusSelect.openedChange.subscribe((opened: boolean) => {
      if (!opened) {
        let statuses = this.parseStatus(this.statuses.value)
        if (statuses != this.pageDefSub$.value.filterByStatuses) this.updatePageDefinition(null, statuses)
      }
    })

  }

  private parseStatus(input: string[]): O.Option<ApplicationStatus[]> {
    return input.includes('All') ? O.none : O.some(input.filter(v => v != 'All').map(s => ApplicationStatus[s]))
  }

  private parseSubmissionType(input: string): O.Option<CreatedBy> {
    return input == "user" ? O.some("user") : input == "admin" ? O.some("admin") : O.none
  }

  triggerRefreshWithoutChange() {
    this.pageDefSub$.next(this.pageDefSub$.value)
  }

  getDisplayNameForStatusFilter(statusFilter: string): string {
    return StatusName[this.applicationStatusEnum[statusFilter]][0]
  }

  /**
   * Update the current page definition and trigger a change on the subject.
   * Sets page to 0 if anything except page index changes.
   */
  updatePageDefinition(textFilter?: O.Option<string[]>, statusFilter?: O.Option<ApplicationStatus[]>, order?: OrderColumn, orderDirection?: OrderColumnDirection, pageIndex?: number, pageSize?: number, creatorFilter?: O.Option<CreatedBy>) {
    let current = this.pageDefSub$.value;
    if (textFilter != null || statusFilter != null || order != null || orderDirection != null || creatorFilter != null || (pageSize != null && current.pageSize != pageSize)) {
      current.pageIndex = 0;
      this.paginator.pageIndex = 0;
    }
    if (textFilter != null) current.filterByText = textFilter;
    if (statusFilter != null) current.filterByStatuses = statusFilter;
    if (order != null) current.order = order;
    if (orderDirection != null) current.orderDirection = orderDirection;
    if (pageIndex != null) current.pageIndex = pageIndex;
    if (pageSize != null) current.pageSize = pageSize;
    if (creatorFilter != null) current.filterByCreator = creatorFilter;

    this.pageDefSub$.next(current);
    this.sessionStorage.setItem('searchState', this.getFormState())
  }

  /**
   * Applies a text filter, called by filter input box event
   */
  applyTextFilter($event: KeyboardEvent) {
    let next = this.parseSearchQuery(this.filterInput.value)
    if (O.isNone(next)) {
      return
    }
    let unpacked = O.getOrElse<O.Option<string[]>>(() => O.none)(next)
    if ($event.key === "Enter") {
      this.textFilterSub$.next(unpacked)
    } else {
      this.textFilterSubWithDebounce$.next(unpacked)
    }
  }

  /**
   * Triggered when any status except Select All is checked
   */
  toggleIndividual() {
    if (this.statuses.value.filter((v: string) => v != 'All').length == this.statusList.length) {
      this.allSelected.select();
    } else if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
  }

  /**
   * Triggered when Select All status is checked
   */
  toggleAll() {
    if (this.allSelected.selected) {
      this.statuses.setValue(this.statusList.concat(['All']));
    } else {
      this.statuses.setValue([]);
    }
  }

}

export function parseSearchFilter(search: string): SearchFilterDefinition {
  return JSON.parse(atob(search))
}

export interface SearchFilterDefinition {
  order: OrderColumn,
  direction: OrderColumnDirection,
  statuses: string[],
  submissionType: string,
  filter: string,
  pageIndex: number,
  pageSize: number
}