import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentPendingEmployeeError, PaymentPendingEmployeeFileErrorReason, PaymentPendingEmployeeOutcome, PaymentPendingEmployeeResult } from 'shared';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-upload-paymentpending',
  templateUrl: './upload-paymentpending.component.html',
  styleUrls: ['./upload-paymentpending.component.css']
})

export class UploadPaymentPendingComponent {

  file = new UntypedFormControl('file', [Validators.required, fileValidator])
  public showProgress = false;
  public uploadEnabled = true;

  status: UploadStatus = UploadStatus.NotUploaded;
  error: PaymentPendingEmployeeError = null;
  success: PaymentPendingEmployeeResult[] = null;

  UploadStatusEnum = UploadStatus;
  PaymentEmployeeFileErrorReasonEnum = PaymentPendingEmployeeFileErrorReason;
  OutcomeName = OutcomeName;
  
  constructor(public dialogRef: MatDialogRef<UploadPaymentPendingComponent>, private snackBar: MatSnackBar, private adminService: AdminService) { }

  @Output() onUpload = new EventEmitter();

  fileUpload() {
    this.status = UploadStatus.NotUploaded;
    this.error = null;
    this.success = null;
    const formData = new FormData();
    const fileData = this.file.value.files[0];
    formData.append("file", fileData, fileData.name)

    if (!this.file.valid) {
      this.snackBar.open("Please upload a file of the correct type.", "Dismiss")
      return false;
    } else {
      this.showProgress = true
    }

    this.adminService.uploadPaymentPending(formData)
      .subscribe(res => {
        this.snackBar.open("Applications updated.", "Dismiss")
        this.showProgress = false
        this.success = res
        this.status = UploadStatus.Success
        this.uploadEnabled = false;
      }, (error: HttpErrorResponse) => {
        this.showProgress = false
        console.error(error);
        this.snackBar.open("Failed to upload the file. Please check the input and try again.", "Dismiss")
        if (error.status == 500){
          this.status = UploadStatus.InternalServerError
        } else if (error.status == 400){
          this.status = UploadStatus.BadRequestError
          this.error = error.error as PaymentPendingEmployeeError;
        } else {
          this.status = UploadStatus.UnspecifiedError
        }
      });
  }

  close(){
    this.dialogRef.close()
  }

}

export function fileValidator(control: AbstractControl) {
  try {
    if (!control.value.fileNames.toLowerCase().endsWith('.csv')) {
      return { fileExtensionValid: true }
    }
  } catch (e) {
    return { fileExtensionValid: true }
  }
  return null;
}

export enum UploadStatus {
  NotUploaded = 0,
  UnspecifiedError = 1,
  InternalServerError = 2,
  BadRequestError = 3,
  Success = 4
}

export const OutcomeName: { [key in PaymentPendingEmployeeOutcome]: [string, string] } = {
  [PaymentPendingEmployeeOutcome.Success]: ["Success", "primary"],
  [PaymentPendingEmployeeOutcome.IncorrectStatus]: ["Incorrect app. status", "warn"],
  [PaymentPendingEmployeeOutcome.MissingClientId]: ["No app. found matching Client ID", "warn"],
  [PaymentPendingEmployeeOutcome.PaymentAlreadyPresent]: ["Historic Batch ID already present", "warn"]
}