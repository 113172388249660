import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationStatus } from 'shared';
import { AdminService } from '../../admin.service';
import { StatusUpdateComponent } from '../status-update/status-update.component';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent extends StatusUpdateComponent {
  status: ApplicationStatus = ApplicationStatus.IdConfirmed;

  constructor(adminService: AdminService, @Inject(MAT_DIALOG_DATA) data: string, snackBar: MatSnackBar) {
    super(adminService, data, snackBar);
  }

  conflictFound(): boolean {
    return this.errorCode === 409;
  }

}
