<table *ngIf="hasErrors" mat-table [dataSource]="tableData" class="mat-elevation-z2">
  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef>Level</th>
    <td mat-cell *matCellDef="let error">
      <div fxLayout="row">
        <div class="mobile-label">Level</div>
        <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="4px">
          <span>{{error.level}}</span>
          <mat-icon color="warn" *ngIf="error.level == ValidationErrorLevel.Critical">error</mat-icon>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <div fxLayout="row">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let error">
        <div class="mobile-label">Name</div>
        {{error.name}}
      </td>
    </div>
  </ng-container>
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>User values</th>
    <td mat-cell *matCellDef="let error">
      <div fxLayout="row">
        <div class="mobile-label">User values</div>
        <div fxLayout="column">
          <div *ngFor="let appValue of error.applicationValues">
            {{appValue}}
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="database">
    <th mat-header-cell *matHeaderCellDef>Database values</th>
    <td mat-cell *matCellDef="let error">
      <div fxLayout="row">
        <div class="mobile-label">Database values</div>
        <div fxLayout="column">
          <div *ngFor="let dbValue of error.previousEmployeeValues">
            {{dbValue}}
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="!hasErrors" fxLayoutAlign="center center">
  <p>There were no validation errors</p>
</div>