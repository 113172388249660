<h1 mat-dialog-title>Payment Confirmation</h1>

<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutGap=10px fxLayoutAlign="start stretch">
        <p>Please upload a payment confirmation file</p>
        <mat-form-field appearance="fill">
            <mat-label>Select file (.csv)</mat-label>
            <ngx-mat-file-input [formControl]="file" placeholder="Basic Input" [accept]="'.csv'">
            </ngx-mat-file-input>
            <mat-error *ngIf="file.hasError('fileExtensionValid')">
                Please upload a '.csv' file.
            </mat-error>
            <mat-icon matSuffix> folder </mat-icon>
        </mat-form-field>

        <mat-accordion *ngIf="status == UploadStatusEnum.Success">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Success <mat-icon color="primary">done</mat-icon>
                    </mat-panel-title>
                    <mat-panel-description>The following client id's were updated to Paid Status:</mat-panel-description>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutGap=10px fxLayoutAlign="start stretch">
                    <table mat-table [dataSource]="success" class="mat-elevation-z8">
                        <ng-container matColumnDef="clientId">
                            <th mat-header-cell *matHeaderCellDef> Client ID </th>
                            <td mat-cell *matCellDef="let element"> {{element.clientId}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['clientId']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['clientId'];"></tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion
            *ngIf="status == UploadStatusEnum.UnspecifiedError || status == UploadStatusEnum.InternalServerError">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Failure <mat-icon color="warn">error</mat-icon>
                    </mat-panel-title>
                    <mat-panel-description>The following error was encountered:</mat-panel-description>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutGap=10px fxLayoutAlign="start stretch">
                    <p *ngIf="status == UploadStatusEnum.UnspecifiedError">An unspecified error was encountered whilst
                        processing the file. Please consult to console logs for further information.</p>
                    <p *ngIf="status == UploadStatusEnum.InternalServerError">An internal server error was encountered
                        whilst
                        processing the file.</p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion
            *ngIf="status == UploadStatusEnum.BadRequestError && error.reason != PaidEmployeeFileErrorReasonEnum.ApplicationError">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Failure <mat-icon color="warn">error</mat-icon>
                    </mat-panel-title>
                    <mat-panel-description>The following errors were encountered whilst parsing the uploaded file:
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutGap=10px fxLayoutAlign="start stretch">
                    <table mat-table [dataSource]="error.errors" class="mat-elevation-z8">
                        <ng-container matColumnDef="error">
                            <th mat-header-cell *matHeaderCellDef> Error Description </th>
                            <td mat-cell *matCellDef="let element"> {{element}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="['error']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['error'];"></tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion
        *ngIf="status == UploadStatusEnum.BadRequestError && error.reason == PaidEmployeeFileErrorReasonEnum.ApplicationError">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Failure <mat-icon color="warn">error</mat-icon>
                </mat-panel-title>
                <mat-panel-description>Errors were encountered whilst updating applications:
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutGap=10px fxLayoutAlign="start stretch">
                <table mat-table [dataSource]="error.results" class="mat-elevation-z8">
                    <ng-container matColumnDef="clientId">
                        <th mat-header-cell *matHeaderCellDef> Client ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.clientId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="outcome">
                        <th mat-header-cell *matHeaderCellDef> Outcome </th>
                        <td class="status-cell" mat-cell *matCellDef="let element">
                          <lib-status-indicator class="table-status-indicator" [statusName]="OutcomeName[element.outcome]"></lib-status-indicator>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="['clientId', 'outcome']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['clientId', 'outcome'];"></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div fxLayout="row" fxLayoutGap=10px fxLayoutAlign="center center">
        <button mat-raised-button (click)="fileUpload()" [disabled]="!file.valid || showProgress || !uploadEnabled"
            color="primary">Upload</button>
        <button mat-button (mousedown)="close()" (click)="close()" [disabled]="showProgress">Close</button>
    </div>
    <mat-progress-bar mode='indeterminate' *ngIf="showProgress"></mat-progress-bar>

</mat-dialog-actions>