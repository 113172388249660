import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedApplicationQuery, PagedApplications } from './admin-models';
import { ENVIRONMENT } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class AdminApplicationService {

  pagedApplicationsURL: string;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
    this.pagedApplicationsURL = this.environment.backendURL + '/applications/list';
  }

  getPagedApplications(query: PagedApplicationQuery): Observable<PagedApplications> {
    return this.http.post<PagedApplications>(this.pagedApplicationsURL, query)
  }

}

