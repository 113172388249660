import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AdminApplicationResponse, ApplicationRequest, ApplicationStatus, Comment, ENVIRONMENT, PaidEmployeeResult, PaymentPendingEmployeeResult } from 'shared';
import { LockStatus } from './admin-models';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  applicationsURL: string;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
    this.applicationsURL = this.environment.backendURL + '/applications';
  }

  getApplication(id: string): Observable<AdminApplicationResponse> {
    return this.http.get<AdminApplicationResponse>(`${this.applicationsURL}/${id}`)
  }

  getApplicationLock(id: string, sessionId: string): Observable<LockStatus> {
    return this.http.put<LockStatus>(`${this.applicationsURL}/${id}/lock/${sessionId}`, {})
  }

  createApplication(emailAddress: string): Observable<AdminApplicationResponse> {
    return this.http.post<AdminApplicationResponse>(this.applicationsURL, { contactEmail: emailAddress });
  }

  updateApplication(id: string, application: ApplicationRequest): Observable<AdminApplicationResponse> {
    return this.http.put<AdminApplicationResponse>(`${this.applicationsURL}/${id}`, application)
  }

  addComment(id: string, commentValue: string): Observable<Comment> {
    return this.http.post<Comment>(`${this.applicationsURL}/${id}/comments`, { comment: commentValue });
  }

  updateApplicationStatus(id: string, appStatus: ApplicationStatus, message: string = null): Observable<AdminApplicationResponse> {
    return this.http.put<AdminApplicationResponse>(`${this.applicationsURL}/${id}/status`, {
      status: appStatus,
      reviewerMessage: message
    });
  }

  downloadApplication(startDate: string, endDate: string): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(`${this.applicationsURL}/download?startDate=${startDate}&endDate=${endDate}`, {
      responseType: 'arraybuffer',
      observe: 'response'
    })
  }

  uploadPaymentPending(formData: FormData): Observable<PaymentPendingEmployeeResult[]> {
    return this.http.post<PaymentPendingEmployeeResult[]>(`${this.applicationsURL}/paymentpending`, formData);
  }

  uploadPaid(formData : FormData) : Observable<PaidEmployeeResult[]> {
    return this.http.post<PaidEmployeeResult[]>(`${this.applicationsURL}/paid`, formData);
  }


  downloadSupportingDocuments(id: string): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(`${this.applicationsURL}/${id}/documents`, {
      responseType: 'arraybuffer',
      observe: 'response'
    })
  }

}
