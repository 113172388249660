export const environment = { production: "",
disableMsal: false,
clientId: "58f42f4e-5695-44ae-83ae-a131b00152ff",
redirectBase: "https://admin.remediation.nbs.co.nz",
authorityBase: "nznbsprodadmin001.b2clogin.com",
loginAuthority: "https://nznbsprodadmin001.b2clogin.com/nznbsprodadmin001.onmicrosoft.com/B2C_1_sign_in",
passwordResetAuthority: "https://nznbsprodadmin001.b2clogin.com/nznbsprodadmin001.onmicrosoft.com/B2C_1_password_reset",
backendURL: "https://admin.remediation.nbs.co.nz/api",
backendScopes: ["https://nznbsprodadmin001.onmicrosoft.com/58f42f4e-5695-44ae-83ae-a131b00152ff/Backend.Access"]
 };
