import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Custom paginator label class that hides the total page count (due to unknown item length)
 */
@Injectable()
export class MatPaginatorIntlApplicationList extends MatPaginatorIntl {

    getRangeLabel = function (page, pageSize, length) {
        return `Page ${page + 1}`;
    };

}