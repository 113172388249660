import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of, pipe, throwError } from "rxjs";
import { ApplicationOverview, PagedApplicationQuery, OrderColumn, OrderColumnDirection, CreatedBy } from '../../admin-models'
import { AdminApplicationService } from '../../admin-application.service'
import { ApplicationStatus } from 'shared';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import * as E from 'fp-ts/es6/Either'
import * as O from 'fp-ts/es6/Option'
import * as F from 'fp-ts/es6/function'
import { SessionStorageService } from "./session-storage.service";

interface ApplicationRecords {
  records: ApplicationOverview[];
  hasMore: boolean;
}
export interface ApplicationPageDefinition {
  pageIndex: number;
  pageSize: number;
  order: OrderColumn;
  orderDirection: OrderColumnDirection;
  filterByStatuses: O.Option<ApplicationStatus[]>;
  filterByText: O.Option<string[]>;
  filterByCreator: O.Option<CreatedBy>;
}

export class ApplicationOverviewDataSource implements DataSource<ApplicationOverview> {

  private applicationOverviewSubject = new BehaviorSubject<ApplicationOverview[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  //private tokenHistory: Map<string, string[]> = new Map();

  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(private adminApplicationService: AdminApplicationService, clearSessionCache: boolean, private sessionStorage: SessionStorageService) {
    if (clearSessionCache) {
      this.sessionStorage.removeItem('tokenHistory')
    }
  }

  private setTokenHistory(hash: string, tokenHashes: string[]) {
    let history = this.getTokenHistory().set(hash, tokenHashes)
    this.sessionStorage.setItem('tokenHistory', JSON.stringify(history, replacer));
  }

  private getTokenHistory(): Map<string, string[]> {
    let strHistory = this.sessionStorage.getItem('tokenHistory')
    if (strHistory) {
      return JSON.parse(strHistory, reviver);
    } else {
      return new Map();
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<ApplicationOverview[]> {
    return this.applicationOverviewSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.applicationOverviewSubject.complete();
    this.loadingSubject.complete();
  }

  /**
   * Load a new page into the collection given the provided page definition.
   * Note: you cannot skip to a page without having already loaded the previous pages.
   * @param page Page definition
   */
  loadPage(page: ApplicationPageDefinition): Observable<E.Either<Error, boolean>> {

    this.loadingSubject.next(true);

    return this.getPage(page).pipe(
      map(maybeRecords =>
        F.pipe(maybeRecords,
          E.map(
            records => {
              this.applicationOverviewSubject.next(records.records);
              return records.hasMore
            }
          ),
          E.mapLeft(error => {
            this.applicationOverviewSubject.next([]);
            return error
          }))
      ),
      catchError(e => {
        this.applicationOverviewSubject.next([]);
        this.loadingSubject.next(false);
        return throwError(e)
      }),
      finalize(() => this.loadingSubject.next(false))
    )

  }

  private getPage(page: ApplicationPageDefinition): Observable<E.Either<Error, ApplicationRecords>> {
    let hash = this.generateParameterHash(page.pageSize, page.order, page.orderDirection, page.filterByStatuses, page.filterByText, page.filterByCreator);
    let tokenHashes = this.getTokenHistory().get(hash) || [];

    if (tokenHashes.length < page.pageIndex) return throwError(new Error(`Page index ${page.pageIndex} is beyond the available continuation token pages ${tokenHashes.length}`))

    let query: PagedApplicationQuery = this.pageDefinitionToPageQuery(page, page.pageIndex == 0 ? O.none : O.some(tokenHashes[page.pageIndex - 1]))

    return this.adminApplicationService
      .getPagedApplications(query)
      .pipe(
        tap(r => {
          if (tokenHashes.length == page.pageIndex && r.continuationToken != null) {
            tokenHashes.push(r.continuationToken)
            this.setTokenHistory(hash, tokenHashes)
          }
        }),
        map(r => {
          return E.right({ records: r.records, hasMore: r.continuationToken != null })
        }),
        catchError(e => {
          return of(E.left(e));
        })
      )
  }

  private pageDefinitionToPageQuery(page: ApplicationPageDefinition, token: O.Option<string>): PagedApplicationQuery {
    return {
      order: page.order,
      orderDirection: page.orderDirection,
      maxItems: page.pageSize,
      filterByStatuses: F.pipe(page.filterByStatuses, O.getOrElse(() => null)),
      filterByText: F.pipe(page.filterByText, O.getOrElse(() => null)),
      filterByCreator: F.pipe(page.filterByCreator, O.getOrElse(() => null)),
      continuationToken: F.pipe(token, O.getOrElse(() => null))
    }
  }

  private generateParameterHash(pageSize: number, order: OrderColumn, orderDirection: OrderColumnDirection, filterByStatuses: O.Option<ApplicationStatus[]>, filterByText: O.Option<string[]>, filterByCreator: O.Option<CreatedBy>): string {
    let statusString = F.pipe(filterByStatuses, O.map(v => `s=${v.sort((a, b) => a - b).join(',')};`), O.getOrElse(() => ""))
    let filterString = F.pipe(filterByText, O.map(v => `f=${v.sort().join(',')};`), O.getOrElse(() => ""))
    let filterCreator = F.pipe(filterByCreator, O.map(v => `c=${v};`), O.getOrElse(() => ""))
    return `p=${pageSize};o=${order};d=${orderDirection};${statusString}${filterString}${filterCreator}`
  }

}

export function replacer(key, value) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

export function reviver(key, value) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}