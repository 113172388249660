import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationStatus } from 'shared';
import { AdminService } from '../../admin.service';
import { StatusUpdateComponent } from '../status-update/status-update.component';

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.css']
})
export class ReturnComponent extends StatusUpdateComponent {
  status: ApplicationStatus = ApplicationStatus.Returned;
  messageForm: UntypedFormControl;
  adminControlled: boolean = false;
  constructor(public dialogRef: MatDialogRef<ReturnComponent>, adminService: AdminService, @Inject(MAT_DIALOG_DATA) data: string, snackBar: MatSnackBar) {
    super(adminService, data, snackBar);
  }

  ngOnInit() {
    super.ngOnInit();
    this.messageForm = new UntypedFormControl(null, Validators.required);
  }

  getMessage(): string {
    return this.messageForm.value;
  }

  canUpdate(): boolean {
    return this.messageForm.valid;
  }

  close(){
    this.dialogRef.close()
  }
}
