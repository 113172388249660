<div *ngIf="loggedIn" class="mat-typography">
  <div class="navbar-content-container">
    <div fxLayout="column" fxLayoutAlign="center center" id="nav">
      <div class="disclaimer-container" fxFlex fxLayout="row" fxLayoutAlign="center center">
        <div class="disclaimer" fxFlex fxLayout="row" fxLayoutAlign="end center">
          <span class="disclaimer-title">NBS is not a registered bank</span>
        </div>
      </div>
      <mat-toolbar color="primary" class="navbar maybe-elevate-toolbar">
        <a id="nav-brand"><img alt="NBS" src="assets/images/nbs-logo.svg" id="nav-logo"></a>
        <span class="nav-title">{{title}}</span>
        <div fxFlex fxLayout fxLayoutAlign="flex-end">
          <button mat-button (click)="logout()" class="nav-button">
            <mat-icon class="icon" inline="true">exit_to_app</mat-icon> Logout
          </button>
        </div>
      </mat-toolbar>
  
    </div>
  </div>
  <mat-toolbar *ngIf="isIE" class="browser-warning" color="warn" fxLayout="row" fxLayoutAlign="center center"><span>It
      looks like
      you're using Internet Explorer. We recommend using a modern browser such as Chrome as we cannot guarantee all the
      features of this portal will work.</span></mat-toolbar>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>