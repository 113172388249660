import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { pipe } from 'fp-ts/es6/function';
import { map } from 'fp-ts/es6/Array';
import { FieldValidationError } from 'shared';

export enum ValidationErrorLevel {
  Critical = "Critical",
  Minor = "Minor"
}

export interface ValidationError {
  name: string;
  level: ValidationErrorLevel;
  message: string;
  applicationValues: string[];
  previousEmployeeValues: string[];
}
@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.css']
})
export class ValidationErrorsComponent implements OnInit {

  @Input() minorErrors: FieldValidationError[];
  @Input() criticalErrors: FieldValidationError[];

  ValidationErrorLevel = ValidationErrorLevel;

  hasErrors: boolean;
  tableData: MatTableDataSource<ValidationError>;
  displayedColumns: string[] = ['name', 'level', 'user', 'database']
  constructor() { }

  ngOnInit(): void {
    if (this.criticalErrors == null) {
      this.criticalErrors = [];
    }
    if (this.minorErrors == null) {
      this.minorErrors = [];
    }

    this.hasErrors = this.criticalErrors.length > 0 || this.minorErrors.length > 0;
    if (this.hasErrors) {
      let cErrs: ValidationError[] = pipe(
        this.criticalErrors,
        map(e => {
          return {
            name: e.fieldName,
            level: ValidationErrorLevel.Critical,
            message: e.message,
            applicationValues: e.applicationValues,
            previousEmployeeValues: e.previousEmployeeValues
          }
        })
      );

      let mErrs: ValidationError[] = pipe(
        this.minorErrors,
        map(e => {
          return {
            name: e.fieldName,
            level: ValidationErrorLevel.Minor,
            message: e.message,
            applicationValues: e.applicationValues,
            previousEmployeeValues: e.previousEmployeeValues
          }
        })
      );

      this.tableData = new MatTableDataSource(cErrs.concat(mErrs));
    }
  }

}
