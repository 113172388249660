import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AdminApplicationResponse } from 'shared';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-create-new-app',
  templateUrl: './create-new-app.component.html',
  styleUrls: ['./create-new-app.component.css']
})
export class CreateNewAppComponent implements OnInit {

  @Output() created = new EventEmitter<AdminApplicationResponse>();
  emailAddress = new UntypedFormControl('', [Validators.required, Validators.email])
  creating: boolean = false;

  constructor(public dialogRef: MatDialogRef<CreateNewAppComponent>, private adminService: AdminService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  createApplication(): void {
    this.creating = true;
    this.adminService.createApplication(this.emailAddress.value)
      .subscribe(a => {
        this.created.emit(a)
        this.creating = false;
      },
      error => {
        console.error(error);
        this.creating = false;
        this.snackBar.open("Error creating application. Please try again later.", "Dismiss")
      })
  }

  close(){
    this.dialogRef.close()
  }

}
