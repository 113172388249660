import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserRefreshService {

    browserRefresh: boolean = false;

    getRefreshStatus(): boolean {
        return this.browserRefresh
    }

    setRefreshStatus(refreshStatus) {
        this.browserRefresh = refreshStatus
    }

}