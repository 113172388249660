<h3 mat-dialog-title>Reject Application</h3>

<div mat-dialog-content class="mat-typography">
  <p>Are you sure you want to reject the application? </p>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutGap=10px>
  <lib-button-spinner [spinning]="updating" color="warn" buttonType="mat-raised-button" (buttonClick)="update()">
    Yes, Reject
  </lib-button-spinner>
  <button mat-button mat-dialog-close>Cancel</button>
</div>
