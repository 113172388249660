import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewAppComponent } from './create-new-app/create-new-app.component';
import { Router } from '@angular/router';
import { ApplicationListComponent } from './application-list/application-list.component';
import { UploadPaymentPendingComponent } from './upload-paymentpending/upload-payment.component';
import { UploadPaidComponent } from './upload-paid/upload-paid.component';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('appList') private appList: ApplicationListComponent;

  dialogConfig = { position: { top: '150px' }, width: '500px' };

  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
  }

  openCreateAppDialog() {
    let dialogRef = this.dialog.open(CreateNewAppComponent, this.dialogConfig)

    dialogRef.componentInstance.created.subscribe(a => {
      dialogRef.close();
      this.router.navigate([`/applications/${a.id}`])
    })

  }

  openUploadDialog() {
    this.dialog.open(UploadPaymentPendingComponent).afterClosed()
    .subscribe(() => this.appList.triggerRefreshWithoutChange());
  }

  openPaidUploadDialog() {
    this.dialog.open(UploadPaidComponent).afterClosed()
    .subscribe(() => this.appList.triggerRefreshWithoutChange());
  }

}
