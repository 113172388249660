<div mat-dialog-container class="mat-typography">
  <h1 mat-dialog-title>Create new application</h1>

  <div mat-dialog-content>
      <div fxLayout="column" fxLayoutGap=10px fxLayoutAlign="start stretch">
        <p>Please provide a contact email for the application</p>
        <lib-email-address [fc]="emailAddress" label="Contact email"></lib-email-address>
          <div fxLayout="row" fxLayoutGap=10px fxLayoutAlign="center center">
              <lib-button-spinner color="primary" [spinning]="creating" (buttonClick)="createApplication()" buttonType="mat-raised-button"
              [disabled]="!emailAddress.valid">Create</lib-button-spinner>
              <button mat-button (mousedown)="close()" (click)="close()">Close</button>
          </div>
      </div>
  </div>
</div>
