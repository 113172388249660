<div class="mat-typography">
  <h3 mat-dialog-title>Approve Application</h3>

  <div mat-dialog-content class="mat-typography">
    <p>Are you sure you want to approve the application? </p>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap=10px>
    <lib-button-spinner *ngIf="!conflictFound()" [spinning]="updating" color="primary" buttonType="mat-raised-button"
      (buttonClick)="update()">
      Yes, Approve
    </lib-button-spinner>
    <p *ngIf="conflictFound()" class="warn-text">The Client ID used in this application has already been used by a
      previously validated application. Please either reject this application or return it to the user.</p>
    <button mat-button mat-dialog-close>Cancel</button>
  </div>
</div>
