import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MsalInterceptor } from '@azure/msal-angular';
import { AuthenticationService } from 'shared';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.disableMsal) {

  console.error("Disabling MSAL service");

  AuthenticationService.prototype.isLoggedIn = () => true;

  MsalInterceptor.prototype.intercept = (req, next) => {
    const access = localStorage.getItem('access_token');
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${access}`
      }
    });
    return next.handle(req);
  };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
