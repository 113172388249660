import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent  } from 'shared'

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'applications/:applicationId', component: ApplicationDetailComponent},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
